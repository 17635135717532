<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> New Receive</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputAgent" class="col-sm-2 col-form-label">Agent <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select class="form-control" v-model="receiveRequest.agentId" @change="handleChange()" >
										<option value="">Choose Agent</option>
										<option v-for="agent in agentList" :value="agent.id">{{agent.name}}</option>
									</select>
									<small class="text-danger">{{validationData.agentId}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputDate" class="col-sm-2 col-form-label">Date <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="date" v-model="receiveRequest.date" @blur="handleChange()"
										class="form-control" id="inputDate" >
									<small class="text-danger">{{validationData.date}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputReceiveNo" class="col-sm-2 col-form-label">Receive No <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="receiveRequest.receiveNo" @blur="validationRule()"
										class="form-control" id="inputReceiveNo" >
									<small class="text-danger">{{validationData.receiveNo}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputOrderCount" class="col-sm-2 col-form-label">Order Count <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="receiveRequest.orderCount" @blur="validationRule()"
										class="form-control" id="inputOrderCount" >
									<small class="text-danger">{{validationData.orderCount}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputTotalPrice" class="col-sm-2 col-form-label">Total Price <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="receiveRequest.totalPrice" @blur="validationRule()"
										class="form-control" id="inputTotalPrice" >
									<small class="text-danger">{{validationData.totalPrice}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputCommissionable" class="col-sm-2 col-form-label">Commissionable <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="receiveRequest.commissionable" @blur="validationRule()"
										class="form-control" id="inputCommissionable" >
									<small class="text-danger">{{validationData.commissionable}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputCommissionAmount" class="col-sm-2 col-form-label">Commission Amount <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="receiveRequest.commisionAmount" @blur="validationRule()"
										class="form-control" id="inputCommissionAmount" >
									<small class="text-danger">{{validationData.commisionAmount}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'receiveCreate',
		metaInfo: {
            title: "Receive Create",
            titleTemplate: "%s ← SHRE Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				agentList : [],
				receiveRequest: {
					agentId: "",
					date: "",
					receiveNo: "",
					commissionable : "",
					commisionAmount : "",
					orderCount : "",
					totalPrice : ""
				},
				validationData: {
					agentId: "",
					date: "",
					receiveNo: "",
					commissionable : "",
					commisionAmount : "",
					orderCount : "",
					totalPrice : ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				receiveStoreAction: 'receiveStoreAction',
				receiveDetailAction: 'receiveDetailAction',
				receiveCheckAction: 'receiveCheckAction'
			}),
			async getFields(){
				let option = {
					type: "store"
				}
				await this.receiveDetailAction({
					...option
				}).then(res => {
					this.agentList = res.data.agent
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async handleChange() {
				this.validationRule()
				if(this.receiveRequest.agentId !="" && this.receiveRequest.date != ""){
					this.checkReceive()
				}
			},
			async checkReceive(){
				let option = {
					agentId: this.receiveRequest.agentId,
					date: this.receiveRequest.date
				}
				await this.receiveCheckAction({
					...option
				}).then(res => {
					this.receiveRequest.commissionable = res.data.commissionable
					this.receiveRequest.commisionAmount = res.data.commisionAmount
					this.receiveRequest.orderCount = res.data.orderCount
					this.receiveRequest.totalPrice = res.data.totalPrice
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.receiveRequest
				await this.receiveStoreAction({
					...option
				}).then(res => {
					console.log(res)
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = false)
			},
			back() {
                this.$router.replace({ path: 'receive-list', query: {page: 1, orderBy: 'desc', sortKey: 'created_at', limit: 10, date: '', id: this.agentId, receiveNo : ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.receiveRequest.agnetId == "") {
					this.validationData.agnetId = "The agnet field is required."
					isValidation.push(true)
				} else {
					this.validationData.agnetId = ""
					isValidation.push(false)
				}
				if (this.receiveRequest.date == "") {
					this.validationData.date = "The date field is required."
					isValidation.push(true)
				} else {
					this.validationData.date = ""
					isValidation.push(false)
				}
				if (this.receiveRequest.receiveNo == "") {
					this.validationData.receiveNo = "The receive no field is required."
					isValidation.push(true)
				} else {
					this.validationData.receiveNo = ""
					isValidation.push(false)
				}
				if (this.receiveRequest.commissionable == "") {
					this.validationData.commissionable = "The commissionable field is required."
					isValidation.push(true)
				} else {
					this.validationData.commissionable = ""
					isValidation.push(false)
				}
				if (this.receiveRequest.commisionAmount == "") {
					this.validationData.commisionAmount = "The commision amount field is required."
					isValidation.push(true)
				} else {
					this.validationData.commisionAmount = ""
					isValidation.push(false)
				}
				if (this.receiveRequest.orderCount == "") {
					this.validationData.orderCount = "The order count field is required."
					isValidation.push(true)
				} else {
					this.validationData.orderCount = ""
					isValidation.push(false)
				}
				if (this.receiveRequest.totalPrice == "") {
					this.validationData.totalPrice = "The total price field is required."
					isValidation.push(true)
				} else {
					this.validationData.totalPrice = ""
					isValidation.push(false)
				}
				
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>